.c-logo_grid {

  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 3rem 0;

  &--item {
    @include breakpoint(large) {
      margin-bottom: 0;
    }
  }

}
