.c-header {

  position: relative;
  background: #222;
  overflow: hidden;
  // blend mode optional at this stage; will be used more in the next demo.
  transition: 3s;
  background-size: cover;
  overflow: hidden;

  &--background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;

    .slide {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center center;
    }
  }

  &--video {
    position: absolute;
    z-index: 1;

    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &--content {
    position: relative;
    z-index: 2;

    &--wrapper {
      height: 100vh;
      min-height: 600px;

      @include breakpoint(medium){
        height: 70vh;
      }

      &--text {

        margin-top: 50px;

        @include breakpoint(medium){
          margin-top: 0;
        }


        h1 {
          color: $white;
        }
        p {
          color: $white;
          font-size: 19px;
          line-height: 1.5;
          @include breakpoint(medium){
            font-size: 20px;
          }
        }
      }

    }

  }

}
