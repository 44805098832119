// Basic start + sticky footer
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}

// Wrapper
.row {

  &.expanded {
    width: 100%;
  }

  .wrapper {
    width: $main-wrapper;
    max-width: 100%;
    margin: auto;
  }

}

// No blue outline
*:focus {
  outline: 0;
}


.ui-widget-content .ui-icon {
	background-image: url("/assets/images/datepicker/ui-icons_444444_256x240.png")/*{iconsContent}*/;
}
.ui-widget-header .ui-icon {
	background-image: url("/assets/images/datepicker/ui-icons_444444_256x240.png")/*{iconsHeader}*/;
}
.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon,
.ui-button:hover .ui-icon,
.ui-button:focus .ui-icon {
	background-image: url("/assets/images/datepicker/ui-icons_555555_256x240.png")/*{iconsHover}*/;
}
.ui-state-active .ui-icon,
.ui-button:active .ui-icon {
	background-image: url("/assets/images/datepicker/ui-icons_ffffff_256x240.png")/*{iconsActive}*/;
}
.ui-state-highlight .ui-icon,
.ui-button .ui-state-highlight.ui-icon {
	background-image: url("/assets/images/datepicker/ui-icons_777620_256x240.png")/*{iconsHighlight}*/;
}
.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
	background-image: url("/assets/images/datepicker/ui-icons_cc0000_256x240.png")/*{iconsError}*/;
}
.ui-button .ui-icon {
	background-image: url("/assets/images/datepicker/ui-icons_777777_256x240.png")/*{iconsDefault}*/;
}
