.c-content_row {

  padding: 5rem 0 0;

  &--title {
    font-size: 36px;
  }

  &--lead {
    font-size: 22px;
    max-width: 800px;
    margin: 0 auto;
  }


  // &--text_column {
  //   @include breakpoint(medium) {
  //     padding-right: 4rem;
  //   }
  // }

  &:last-child {
    padding-bottom: 5rem;
  }

}
