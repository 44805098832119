.c-topbar {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 90;

  .columns {
    padding: 1rem;
    @include breakpoint(medium) {
      padding: 2rem;
    }
  }

  &--logo {
    img {
      max-width: 175px;
    }
  }

  &--menu {
    a.button{
      padding: 0.75em 1.1em;
      font-size: 105%;
      @include breakpoint(medium){
        padding: 0.85em 1.25em;
      }
    }
    a:not(.button) {
      padding: 1em 1.75em;
      color: $white;
    }
  }

  &.bg-light {
    a:not(.button) {
      color: $black;
    }
  }

}
