.c-form {

  form {
    position: relative;
    width: 100%;
  }

  &--row {
    padding: 2rem;
    border: 1px dashed #ddd;
    margin: 1rem 0;
    border-radius: 12px;

    legend {
      font-size: 22px;
      font-weight: bold;
      margin: 0 0 20px;
    }

    &--item {
      input[type='radio'],
      input[type='checkbox'] {
        display: none;
        padding: 0;
        margin: 0;
      }
      label {
        font-size: 16px;
        line-height: 1;
        color: #bbb;
        border: 1px solid #ccc;
        border-radius: 40px;
        margin: 0 10px 10px 0;
        padding: 0.9rem 1.1rem 0.8rem;
        display: block;
        float: left;
        transition: 0.1s all;
        &:before {
          content: '';
          width: 15px;
          height: 12px;
          margin-top: 1px;
          background: #eee no-repeat;
          -webkit-mask-image: url('/assets/images/icon-check.svg');
          mask-image: url('/assets/images/icon-check.svg');
          display: block;
          float: left;
          margin-right: 10px;
        }
        &.active {
          background: $color-blue;
          border: 1px solid $color-blue;
          color: $white;
          transition: 0.1s all;
          &:before {
            background: $white no-repeat;
          }
        }
      }

      &--label {
        display: block;
        float: left;
        width: 150px;
        clear: both;
        line-height: 1;
        padding: 1.1rem 0;
      }

      &--input {
        display: block;
        float: left;
        padding: 0.2rem 0;

        input[type='text'],
        input[type='email'] {
          border: 1px solid #ccc;
          box-shadow: none;
          border-radius: 40px;
          font-size: 16px;
          padding: 0.2rem 1.2rem;
          height: 44px;
          width: 288px;
          max-width: 100%;
          margin-bottom: 10px;
        }

      }

    }

    &-submit {
      button {
        border-radius: 40px;
        margin-top: 20px;
      }
    }

  }

}
