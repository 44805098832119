#footer {
  margin-top: 60px;
  position: relative;
  min-height: 250px;
  background: url('https://www.poulissen.nl/themes/poulissen/includes/img/banners/banner_standaard.png') no-repeat center 80%;
  background-size: cover;
  @include breakpoint(medium){
    min-height: 500px;
  }
}
