.small-order-1 {
  order: 1;
  @include breakpoint(large) {
    order: 2;
  }
}

.small-order-2 {
  order: 2;
  @include breakpoint(large) {
    order: 1;
  }
}
