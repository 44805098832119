.c-card_grid {

  &--item {
    padding: 2rem 1rem 0;

    img {
      display: block;
      margin: 0 0 25px;
    }

  }

  &--more {
    padding: 40px 0 0;
    a {
      font-size: 22px;
      text-decoration: underline;
    }
  }

}
