// Base settings
// ===========================
$main-wrapper: rem-calc(1600);

// Fonts
// ===========================
$font-size: rem-calc(16px);

$font-main: 'proxima-nova', sans-serif;
$font-header: 'proxima-nova', sans-serif;

// Colors
// ===========================
$color-blue: #4A90E2;
$color-white: #fff;

$color-anchor: #4A90E2;
$color-button-txt: $color-white;
$color-button-back: #4A90E2;
